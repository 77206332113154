import { Box, Spinner } from "@chakra-ui/react";

export default function Loading() {
  return (
    <Box
      display={"flex"}
      flexDirection="column"
      justifyContent={"center"}
      py={{ base: 100 }}
      alignItems="center"
    >
      <Spinner
        thickness="10px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Box>
  );
}
