import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  useDisclosure,
} from "@chakra-ui/react";

import { api } from "Commons.generated";
import { uploadFiles } from "libs";

type UploadFile = {
  title?: string;
  multiple?: boolean;
  accept?: string;
  callback: (value: string[]) => void;
};

type CurrentUploadFileContextType = (data: UploadFile) => void;
const UploadFileContext =
  React.createContext<CurrentUploadFileContextType | null>(null);
UploadFileContext.displayName = "UploadFileContext";

const UploadFileProvider = ({ children }: any) => {
  const [progress, setProgress] = useState(0);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [options, setOptions] = useState<UploadFile>({
    callback: (value: string[]) => [],
  });
  const [createFileUpload, { isLoading: isCreatingFileUpload }] =
    api.useCreateUploadMutation();

  const inputRef = useRef<HTMLInputElement>(null);

  const uploadFile = useCallback(
    (options: UploadFile) => {
      setOptions(options);
      inputRef.current?.showPicker();
    },
    [setOptions]
  );

  useEffect(() => {
    if (isOpen) {
      setProgress(0);
    }
  }, [isOpen, setProgress]);

  const onCancel = useCallback(() => {
    if (options.callback) {
      options.callback([]);
    }
    onClose();
  }, [onClose, options]);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const mimeType = file.type;
      onOpen();
      setProgress(0);
      try {
        const result = await createFileUpload({ mimeType }).unwrap();
        const form = new FormData();
        Object.keys(result.createUpload.fields).forEach((key) =>
          form.append(key, result.createUpload.fields[key])
        );
        form.append("file", file);

        await uploadFiles(result.createUpload.url, form, setProgress);
        options.callback([result.createUpload.filename]);
      } catch (error) { }
      onClose();
    }
  };

  return (
    <UploadFileContext.Provider value={uploadFile}>
      <input
        type="file"
        ref={inputRef}
        hidden
        onChange={handleChange}
        multiple={options.multiple || false}
        accept={options.accept}
      />
      <Modal isOpen={isOpen} onClose={onCancel}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{options.title || "Subir archivo"}</ModalHeader>
          <ModalBody>
            <Progress hasStripe value={progress * 100} />
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onCancel} disabled={isCreatingFileUpload}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {children}
    </UploadFileContext.Provider>
  );
};

const useUploadFile = () => {
  const currentContext = useContext(UploadFileContext);
  if (!currentContext) {
    throw new Error(
      "useUploadFile has to be used within <UploadFileContext.Provider>"
    );
  }

  const uploadFile = useCallback(
    (options?: Omit<UploadFile, "callback">): Promise<string[]> =>
      new Promise((resolve) => {
        currentContext({
          ...options,
          callback: (result) => resolve(result),
        });
      }),
    [currentContext]
  );

  return uploadFile;
};

export { UploadFileProvider, useUploadFile };
