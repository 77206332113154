import * as Types from './types.generated';

import { api } from 'libs';
module.hot?.accept();
export type GetSignedUrlMutationVariables = Types.Exact<{
  filename: Types.Scalars['NonEmptyString'];
  type: Types.Scalars['NonEmptyString'];
}>;


export type GetSignedUrlMutation = { __typename?: 'Mutation', getSignedUrl: any };

export type CreateUploadMutationVariables = Types.Exact<{
  mimeType: Types.Scalars['NonEmptyString'];
}>;


export type CreateUploadMutation = { __typename?: 'Mutation', createUpload: { __typename?: 'CreateUploadResult', filename: string, url: string, fields: any } };

export type DownloadUriMutationVariables = Types.Exact<{
  fileName: Types.Scalars['NonEmptyString'];
}>;


export type DownloadUriMutation = { __typename?: 'Mutation', downloadURI: any };


export const GetSignedUrlDocument = `
    mutation GetSignedUrl($filename: NonEmptyString!, $type: NonEmptyString!) {
  getSignedUrl(filename: $filename, type: $type)
}
    `;
export const CreateUploadDocument = `
    mutation CreateUpload($mimeType: NonEmptyString!) {
  createUpload(mimeType: $mimeType) {
    filename
    url
    fields
  }
}
    `;
export const DownloadUriDocument = `
    mutation DownloadURI($fileName: NonEmptyString!) {
  downloadURI(filename: $fileName)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    GetSignedUrl: build.mutation<GetSignedUrlMutation, GetSignedUrlMutationVariables>({
      query: (variables) => ({ document: GetSignedUrlDocument, variables })
    }),
    CreateUpload: build.mutation<CreateUploadMutation, CreateUploadMutationVariables>({
      query: (variables) => ({ document: CreateUploadDocument, variables })
    }),
    DownloadURI: build.mutation<DownloadUriMutation, DownloadUriMutationVariables>({
      query: (variables) => ({ document: DownloadUriDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };


