import { createAction, createReducer } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import jwtDecode from "jwt-decode";
import { AuthUser, Role } from "types.generated";
import { APP_ID } from "../../constants";

type Mode = Role.Employee | Role.Admin;

interface AuthState {
  redirect: string | null;
  mode: Mode;
  auth: {
    token: string;
    user: AuthUser;
  } | null;
}

// Modificar los datos del usuario
export const authenticate = createAction<string>("@auth/authenticate");
export const unRedirect = createAction("@auth/un-redirect");
export const setRedirect = createAction<string>("@auth/set-redirect");
export const unAuthenticate = createAction<string | undefined>(
  "@auth/unAuthenticate"
);
export const setMode = createAction<Mode>("@auth/setMode");

const persistConfig = {
  key: APP_ID + "-auth",
  storage: storage,
  whitelist: ["auth", "redirect", "mode"],
};

const initialState = {
  mode: Role.Employee,
  logging: false,
  auth: null,
  redirect: null,
} as AuthState;

const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(authenticate, (state, action) => {
      const { user }: { user: AuthUser } = jwtDecode(action.payload);

      if (
        [Role.Admin, Role.Super, Role.Develop].reduce(
          (acc, e) => acc || (user.roles.find((r) => r === e) ? true : false),
          false
        )
      ) {
        state.mode = Role.Admin;
      } else {
        state.mode = Role.Employee;
      }

      state.auth = {
        token: action.payload,
        user,
      };
    })
    .addCase(unAuthenticate, (state, action) => {
      state.auth = null;
      state.mode = Role.Employee;
      state.redirect = action.payload ? action.payload : null;
    })
    .addCase(unRedirect, (state) => {
      state.redirect = null;
    })
    .addCase(setRedirect, (state, action) => {
      state.redirect = action.payload;
    })
    .addCase(setMode, (state, action) => {
      state.mode = action.payload;
    });
});

export default persistReducer(persistConfig, authReducer);
