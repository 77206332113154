import { api } from "./api.generated";

export const injectedRtkApi = api.enhanceEndpoints({
  addTagTypes: [
    "Certification",
    "Contract",
    "Contract-LIST",
    "Employee",
    "CustomField",
    "EmployeeEditableFields",
    "Position-LIST",
  ],
  endpoints: {
    Positions: {
      providesTags: (result) =>
        result
          ? [
            ...result.positions.map(({ id }) => ({
              type: "Position-LIST" as const,
              id,
            })),
            "Position-LIST",
          ]
          : ["Position-LIST"],
    },
    CreatePosition: {
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData("Positions", undefined, (draft) => {
              draft.positions.unshift({
                ...data.createPosition,
              });
            })
          );
        } catch { }
      },
    },
    UpdatePosition: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Position-LIST", id: arg.payload.id },
      ],
    },
    SoftDeletePosition: {
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            api.util.updateQueryData("Positions", undefined, (draft) => {
              const index = draft.positions.findIndex((e) => e.id === args.id);
              if (index !== -1) {
                draft.positions.splice(index, 1);
              }
            })
          );
        } catch { }
      },
    },
    Certifications: {
      providesTags: (result) =>
        result
          ? [
            ...result.certifications.map(({ id }) => ({
              type: "Certification" as const,
              id,
            })),
            "Certification",
          ]
          : ["Certification"],
    },
    CreateCertification: {
      invalidatesTags: ["Certification", "Contract-LIST"],
    },
    EditCertification: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Certification", id: arg.id },
      ],
    },
    DeleteCertification: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Certification", id: arg.id },
        "Contract-LIST",
        "Contract",
      ],
    },
    Contract: {
      providesTags: (result) =>
        result?.contract
          ? [{ type: "Contract", id: result.contract.id }]
          : ["Contract"],
    },
    GetEmployeeEditableFields: {
      providesTags: ["EmployeeEditableFields"],
    },
    PutEmployeeEditableFields: {
      invalidatesTags: ["EmployeeEditableFields"],
    },
    Contracts: {
      providesTags: (result) =>
        result
          ? [
            ...result.contracts.map(({ id }) => ({
              type: "Contract-LIST" as const,
              id,
            })),
            "Contract-LIST",
          ]
          : ["Contract-LIST"],
    },
    CreateContract: {
      invalidatesTags: ["Contract-LIST", "Certification"],
    },
    SoftDeleteContract: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Contract-LIST", id: arg.id },
        { type: "Contract", id: arg.id },
        "Contract",
        "Contract-LIST",
      ],
    },
    UpdateContract: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Contract", id: arg.id },
        "Contract",
      ],
    },
    Employees: {
      providesTags: (result) =>
        result
          ? [
            ...result.employees.map(({ id }) => ({
              type: "Employee" as const,
              id,
            })),
            "Employee",
          ]
          : ["Employee"],
    },
    Employee: {
      providesTags: (result) =>
        result?.employee
          ? [{ type: "Employee", id: result.employee.id }]
          : ["Employee"],
    },
    CreateEmployee: {
      invalidatesTags: ["Employee"],
    },
    EditEmployee: {
      invalidatesTags: ["Employee"],
    },
    UpdateAmounts: {
      invalidatesTags: ["Employee"],
    },
    ToggleEmployee: {
      invalidatesTags: ["Employee"],
    },
    CustomFields: {
      providesTags: (result) =>
        result
          ? [
            ...result.customFields.map(({ id }) => ({
              type: "CustomField" as const,
              id,
            })),
            "CustomField",
          ]
          : ["CustomField"],
    },
    CreateCustomField: {
      invalidatesTags: ["CustomField"],
    },
    DeleteCustomField: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "CustomField", id: arg.id },
        "CustomField",
      ],
    },
    TerminateEmployee: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Employee", id: arg.id },
        "Employee",
      ],
    },
  },
});

export { injectedRtkApi as api };
export const {
  useLazyGetAllEmployeesQuery,
  useCustomFieldsQuery,
  useEmployeeQuery,
  useLazyEmployeeQuery,
  useEmployeesQuery,
  useContractQuery,
  useUpdateContractMutation,
  useSearchEmployeeQuery,
  useSearchEmployeeWithCertificationsAvailablesQuery,
  usePositionsQuery,
  useCertificationsQuery,
  useContractsQuery,
  useCreateCustomFieldMutation,
  useCreateEmployeeMutation,
  useCreateCertificationMutation,
  useEditCertificationMutation,
  useDeleteCertificationMutation,
  useEditEmployeeMutation,
  useCreateContractMutation,
  useDeleteCustomFieldMutation,
  useSoftDeleteContractMutation,
  useGetEmployeeEditableFieldsQuery,
  usePutEmployeeEditableFieldsMutation,
  useBuildDocumentMutation,
  useToggleEmployeeMutation,
  useTerminateEmployeeMutation,
  useUpdateAmountsMutation,
  useCreatePositionMutation,
  useUpdatePositionMutation,
  useSoftDeletePositionMutation,
  useSimpleSearchEmployeeQuery,
  useLazySimpleSearchEmployeeQuery,
  useGetUserAccountQuery,
} = injectedRtkApi;
