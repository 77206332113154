import { Outlet, Navigate, useLocation } from "react-router-dom";

import { Role } from "types.generated";
import { useAppSelector } from "hooks";
import { userSelector } from "modules/auth/selectors";

function RequireAuth({ role }: { role?: Role | Role[] }) {
  const location = useLocation();
  const user = useAppSelector(userSelector);

  const roles = role ? (Array.isArray(role) ? role : [role]) : [];

  const hasRoles =
    roles &&
    roles.reduce(
      (acc, role) =>
        acc && (roles.find((role_) => role_ === role) ? true : false),
      true
    );

  if (!user || !hasRoles) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <Outlet />;
}

export default RequireAuth;
