import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../../hooks";
import { show, Alert } from "./reducer";

export const useAlert = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const alert = useCallback(
    (alert: Alert) => {
      if (alert.paths === undefined) {
        alert.paths = [location.pathname];
      } else if (
        alert.paths.length !== 0 &&
        !alert.paths.find((path) => path === location.pathname)
      ) {
        alert.paths = [...alert.paths, location.pathname];
      }
      dispatch(show(alert));
    },
    [dispatch, location]
  );

  return alert;
};
