import { createRoot } from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ApiProvider } from "@reduxjs/toolkit/query/react";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "./index.css";

import App from "./App";

import reportWebVitals from "./reportWebVitals";
import store, { persistor } from "./store";

import { api } from "libs";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_CLIENT_ID } from "./constants";
import { PromptProvider } from "components/Prompt";
import { ConfirmProvider } from "components/Confirm";
import { UploadFileProvider } from "components/UploadFile";
import theme from "./theme";

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
  <ChakraProvider theme={theme}>
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <ApiProvider api={api}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <PromptProvider>
              <ConfirmProvider>
                <UploadFileProvider>
                  <App />
                </UploadFileProvider>
              </ConfirmProvider>
            </PromptProvider>
          </PersistGate>
        </Provider>
      </ApiProvider>
    </GoogleOAuthProvider>
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
