import * as Types from '../../types.generated';

import { api } from 'libs';
module.hot?.accept();
export type DocumentsQueryVariables = Types.Exact<{
  documentType?: Types.InputMaybe<Types.DocumentType>;
}>;


export type DocumentsQuery = { __typename?: 'Query', documents: Array<{ __typename?: 'Document', id: string, name: string, type: Types.DocumentType, fields: Array<any>, documentId: string }> };

export type DocumentQueryVariables = Types.Exact<{
  documentId: Types.Scalars['ID'];
}>;


export type DocumentQuery = { __typename?: 'Query', document: { __typename?: 'Document', id: string, name: string, type: Types.DocumentType, fields: Array<any>, documentId: string } };

export type SoftDeleteDocumentMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type SoftDeleteDocumentMutation = { __typename?: 'Mutation', softDeleteDocument?: any };

export type CreateDocumentMutationVariables = Types.Exact<{
  name: Types.Scalars['NonEmptyString'];
  documentType?: Types.InputMaybe<Types.DocumentType>;
}>;


export type CreateDocumentMutation = { __typename?: 'Mutation', createDocument: { __typename?: 'Document', id: string, name: string, type: Types.DocumentType, fields: Array<any>, documentId: string } };

export type EditDocumentMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  payload: Types.EditDocumentPayload;
}>;


export type EditDocumentMutation = { __typename?: 'Mutation', editDocument?: any };

export type DownloadDocumentMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  values?: Types.InputMaybe<Array<Types.FieldDocumentValue> | Types.FieldDocumentValue>;
}>;


export type DownloadDocumentMutation = { __typename?: 'Mutation', downloadDocument: any };


export const DocumentsDocument = `
    query Documents($documentType: DocumentType) {
  documents(type: $documentType) {
    id
    name
    type
    fields
    documentId
  }
}
    `;
export const DocumentDocument = `
    query Document($documentId: ID!) {
  document(id: $documentId) {
    id
    name
    type
    fields
    documentId
  }
}
    `;
export const SoftDeleteDocumentDocument = `
    mutation SoftDeleteDocument($id: ID!) {
  softDeleteDocument(id: $id)
}
    `;
export const CreateDocumentDocument = `
    mutation CreateDocument($name: NonEmptyString!, $documentType: DocumentType) {
  createDocument(input: {name: $name, type: $documentType}) {
    id
    name
    type
    fields
    documentId
  }
}
    `;
export const EditDocumentDocument = `
    mutation EditDocument($id: ID!, $payload: EditDocumentPayload!) {
  editDocument(id: $id, payload: $payload)
}
    `;
export const DownloadDocumentDocument = `
    mutation DownloadDocument($id: ID!, $values: [FieldDocumentValue!]) {
  downloadDocument(id: $id, values: $values)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    Documents: build.query<DocumentsQuery, DocumentsQueryVariables | void>({
      query: (variables) => ({ document: DocumentsDocument, variables })
    }),
    Document: build.query<DocumentQuery, DocumentQueryVariables>({
      query: (variables) => ({ document: DocumentDocument, variables })
    }),
    SoftDeleteDocument: build.mutation<SoftDeleteDocumentMutation, SoftDeleteDocumentMutationVariables>({
      query: (variables) => ({ document: SoftDeleteDocumentDocument, variables })
    }),
    CreateDocument: build.mutation<CreateDocumentMutation, CreateDocumentMutationVariables>({
      query: (variables) => ({ document: CreateDocumentDocument, variables })
    }),
    EditDocument: build.mutation<EditDocumentMutation, EditDocumentMutationVariables>({
      query: (variables) => ({ document: EditDocumentDocument, variables })
    }),
    DownloadDocument: build.mutation<DownloadDocumentMutation, DownloadDocumentMutationVariables>({
      query: (variables) => ({ document: DownloadDocumentDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };


