import { AlertStatus } from "@chakra-ui/react";
import { createAction, createReducer } from "@reduxjs/toolkit";

export interface Alert {
  title?: string;
  description: string;
  status?: AlertStatus;
  closable?: boolean;
  duration?: number;
  paths?: string[];
}

interface AlertState {
  alert: null | Alert;
}

const initial: AlertState = { alert: null };

export const show = createAction<Alert>("@alert/show");
export const hide = createAction("@alert/hide");

const alertReducer = createReducer(initial, (builder) => {
  builder
    .addCase(show, (state, action) => {
      state.alert = {
        ...action.payload,
        closable: action.payload.closable ?? true,
        duration:
          !action.payload.closable &&
          action.payload.closable !== undefined &&
          !action.payload.duration
            ? 10000
            : action.payload.duration,
      };
    })
    .addCase(hide, (state) => {
      state.alert = null;
    });
});

export default alertReducer;
