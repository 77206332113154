import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import {
  useCallback,
  useContext,
  useRef,
  useState,
  createContext,
} from "react";

type Confirm = {
  text: string;
  title?: string;
  callback: () => any | Promise<any>;
};

type CurrentConfirmContextType = (data: Confirm) => void;
const ConfirmContext = createContext<CurrentConfirmContextType | null>(null);
ConfirmContext.displayName = "ConfirmContext";

const ConfirmProvider = ({ children }: any) => {
  const [options, setOptions] = useState<Confirm>({
    text: "",
    callback: () => null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const confirm = useCallback(
    (options: Confirm) => {
      setOptions(options);
      onOpen();
    },
    [onOpen]
  );

  const confirm_ = useCallback(async () => {
    try {
      setIsLoading(true);
      await options.callback();
    } finally {
      setIsLoading(false);
      onClose();
    }
  }, [options, onClose, setIsLoading]);

  return (
    <ConfirmContext.Provider value={confirm}>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {options.title || "Confirmación"}
            </AlertDialogHeader>
            <AlertDialogBody>{options.text}</AlertDialogBody>
            <AlertDialogFooter>
              <Button
                isLoading={isLoading}
                colorScheme="red"
                onClick={confirm_}
              >
                Aceptar
              </Button>
              <Button
                ref={cancelRef}
                variant="ghost"
                onClick={() => onClose()}
                ml={3}
              >
                Cancelar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {children}
    </ConfirmContext.Provider>
  );
};

const useConfirm = () => {
  const currentContext = useContext(ConfirmContext);
  if (!currentContext) {
    throw new Error(
      "useConfirm has to be used within <ConfirmContext.Provider>"
    );
  }

  return currentContext;
};

export { ConfirmProvider, useConfirm };
