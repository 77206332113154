import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  // persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
// import createSagaMiddleware from "redux-saga";
// import { Middleware, Action } from "redux";
// import { createReduxHistoryContext } from "redux-first-history";
// import { createBrowserHistory } from "history";

// import storage from "redux-persist/lib/storage";
// import rootSagas from "./sagas";
import authReducer from "./modules/auth/reducer";
import alertReducer from "./components/alert/reducer";

import { api } from "libs";
import * as middlewares from "./middlewares";

import { createBrowserHistory } from "history";
import {
  createRouterReducer,
  createRouterMiddleware,
} from "@lagunovsky/redux-react-router";

export const history = createBrowserHistory();

const routerMiddleware = createRouterMiddleware(history);

// const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  // router: routerReducer,
  router: createRouterReducer(history),
  auth: authReducer,
  alert: alertReducer,
  [api.reducerPath]: api.reducer,
});

// const persistConfig = {
//   key: process.env.REACT_APP_NAME,
//   storage: storage,
//   whitelist: []
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);
export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        /* ignore persistance actions */
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .prepend(
        routerMiddleware,
        middlewares.listenerMiddleware.middleware
        // sagaMiddleware as Middleware<
        //   (action: Action<"specialAction">) => number,
        //   RootState
        // >
      )
      .concat(api.middleware),
});

// sagaMiddleware.run(rootSagas);

export const persistor = persistStore(store);
// export const history = createReduxHistory(store);

export type AppDispatch = typeof store.dispatch;

export default store;
