import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react";
import { FaSave } from "react-icons/fa";
import React from "react";

interface ModalFormProps extends ModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  title: string;
  isSubmitting?: boolean;
}

function ModalForm({
  children,
  isOpen,
  onClose,
  title,
  onSubmit,
  isSubmitting = false,
  ...modalProps
}: ModalFormProps) {
  return (
    <Modal {...modalProps} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Cancelar
          </Button>
          <Button
            variant="ghost"
            leftIcon={<FaSave />}
            onClick={onSubmit}
            isLoading={isSubmitting}
          >
            Aceptar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ModalForm;
