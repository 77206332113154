import * as yup from "yup";

export const ID_TYPE = ["CC", "CE", "PEP", "PPT", "RUT", "N/A", "DNI", "OTRO"];

export const GENDER = ["M", "F", "OTRO"];

export const CIVIL_STATUS = [
  "CASADO",
  "DIVOCIADO",
  "SOLTERO",
  "UNION LIBRE",
  "UNION MARITAL DE HECHO",
];

export const ENTRANCE_EXAM = [
  "ASEPLOT",
  "CLÍNICA RISARALDA",
  "GMA",
  "GRUPO SUANI",
  "INPROSALUD",
  "IPS SAN GABRIEL",
  "LABORAL SALUD",
  "MORA GONZALES",
  "NO SE REALIZÓ",
  "OMNISALUD",
  "PACIFIC SALUD GROUP",
  "PORSALUD",
  "PORVIDA",
  "PREVENIR",
  "PROENSO",
  "R & L",
  "SALUD LABORAL IPS",
  "VITAL MEDICOS IPS",
];

export const EPS = [
  "ALIANSALUD",
  "ASMET SALUD",
  "CAJACOPI",
  "CAPITAL SALUD",
  "COMFACHOCO",
  "COMFENALCO VALLE",
  "COMPENSAR",
  "CONVIDA",
  "COOSALUD",
  "ECOOPSOS",
  "FAMISANAR",
  "MUTUAL SER",
  "N/A",
  "NUEVA EPS",
  "SALUD MIA",
  "SALUD TOTAL",
  "SANITAS",
  "SOS",
  "SURA",
];

export const COMPENSATION_FUND = [
  "CAJAMAG",
  "COFREM",
  "COMBARRANQUILLA",
  "COMFABOY",
  "COMFACASANARE",
  "COMFACESAR",
  "COMFACHOCO",
  "COMFACOR",
  "COMFACUNDI",
  "COMFAMA",
  "COMFAMILAIR RISARALDA",
  "COMFAMILIAR HUILA",
  "COMFAMILIAR RISARALDA",
  "COMFAMILIAR",
  "COMFANDI",
  "COMFANORTE",
  "COMFENALCO CARTAGENA",
  "COMFENALCO QUINDIO",
  "COMFENALCO SANTANDER",
  "COMFENALCO TOLIMA",
  "COMPENSAR CAJA",
  "CONFANDI",
  "CONFENALCO  TOLIMA",
  "CONFENALCO CARTAGENA",
  "CONFENALCO SANTANDER",
  "CONFENALCO",
  "N/A",
];

export const SEVERANCE_FUND = [
  "COLFONDOS",
  "FNA",
  "N/A",
  "PORVENIR",
  "PROTECCION",
  "SKANDIA",
];

export const COUNTRY_LOCATION = [
  "ARGENTINA",
  "CANADA",
  "CHILE ",
  "COLOMBIA",
  "ESPAÑA",
  "FRANCIA",
  "MEXICO",
  "PERÚ",
  "VENEZUELA",
];

export const CURRENCY = [
  "COP",
  "CLP",
  "EUR",
  "USD",
  "COP/USD",
  "COP/EUR",
  "CLP/USD",
  "CLP/EUR",
];

export const COMPUTER_HARDWARE = [
  "ALQUILADO",
  "EMPRESA",
  "NO ASIGNADO",
  "PERSONAL",
];

export const WITHDRAWAL_REASON = [
  "DESPIDO SIN JUSTA CAUSA",
  "N/A",
  "RENUNCIA VOLUNTARIA",
  "TERMINACIÓN DE CONTRATO CON JUSTA CAUSA",
  "TERMINACIÓN DE CONTRATO EN PERIODO DE PRUEBA",
  "TERMINACIÓN ETAPA LECTIVA",
  "TERMINACIÓN ETAPA PRODUCTIVA",
];

export const PENSION_FUND = [
  "COLFONDOS",
  "COLPENSIONES",
  "N/A",
  "PORVENIR",
  "PROTECCION",
  "SKANDIA",
];

export const ACCOUNT_BANK = [
  "BANCAMIA S.A.",
  "BANCO AGRARIO",
  "BANCO AV VILLAS",
  "BANCO CAJA SOCIAL BCSC SA",
  "BANCO COMPARTIR S.A",
  "BANCO COOPERATIVO COOPCENTRAL",
  "BANCO DAVIVIENDA SA",
  "BANCO DE BOGOTA",
  "BANCO DE OCCIDENTE",
  "BANCO FALABELLA S.A.",
  "BANCO FINANDINA S.A.",
  "BANCO GNB SUDAMERIS",
  "BANCO MULTIBANK S.A.",
  "BANCO MUNDO MUJER",
  "BANCO PICHINCHA",
  "BANCO POPULAR",
  "BANCO PROCREDIT COLOMBIA",
  "BANCO SANTANDER DE NEGOCIOS COLOMBIA S.A",
  "BANCO SERFINANZA S.A",
  "BANCO W S.A.",
  "BANCOLDEX S.A.",
  "BANCOLOMBIA",
  "BANCOOMEVA",
  "BBVA COLOMBIA",
  "CITIBANK",
  "COLTEFINANCIERA S.A",
  "CONFIAR COOPERATIVA FINANCIERA",
  "COOPERATIVA FINANCIERA DE ANTIOQUIA",
  "COOTRAFA COOPERATIVA FINANCIERA",
  "DAVIPLATA",
  "DEEL",
  "FINANCIERA JURISCOOP S.A. COMPAÑIA DE FINANCIAMIENTO",
  "ITAU ANTES CORPBANCA",
  "ITAU",
  "N/A",
  "NEQUI",
  "SCOTIABANK COLPATRIA S.A",
];

export const ACCOUNT_TYPE = ["AHORROS", "CORRIENTE", "PLATAFORMA"];

export const ARL = ["AXXA", "N/A"];

export interface FieldEmployee {
  name: string;
  label: string;
  type?: "text" | "select" | "switch" | "date" | "number";
  readonly?: boolean;
  options?: string[];
  list?: string;
  validation?: any;
}

export const FIELDS_EMPLOYEE: FieldEmployee[] = [
  {
    name: "fullName",
    label: "Nombres y apellidos completo",
    type: "text",
    readonly: true,
  },
  {
    name: "dateStartInText",
    label: "Fecha de ingreso formato texto",
    type: "text",
    readonly: true,
  },
  {
    name: "positionName",
    label: "Cargo",
    type: "text",
    readonly: true,
  },
  {
    name: "salaryInLetters",
    label: "Salario en letras",
    type: "text",
    readonly: true,
  },
  {
    name: "bonusInLetters",
    label: "Bonificación en letras",
    type: "text",
    readonly: true,
  },
  {
    name: "idNumber",
    label: "Número de identificación",
    validation: yup.string().trim().required(),
  },
  {
    name: "idType",
    label: "Tipo de identificación",
    validation: yup.string().required(),
    type: "select",
    options: ID_TYPE,
  },
  {
    name: "firstName",
    label: "Primer nombre",
    validation: yup.string().trim().lowercase().required(),
  },
  {
    name: "middleName",
    label: "Segundo nombre",
    validation: yup.string().lowercase().trim(),
  },
  {
    name: "lastName",
    label: "Primer apellido",
    validation: yup.string().trim().lowercase().required(),
  },
  {
    name: "secondLastName",
    label: "Segundo apellido",
    validation: yup.string().trim().lowercase(),
  },
  {
    name: "personalEmail",
    label: "Email personal",
    validation: yup.string().email().trim().lowercase().required(),
  },
  {
    name: "dateBirth",
    label: "Fecha de nacimiento",
    type: "date",
    validation: yup.string().required(),
  },
  {
    name: "stateBirth",
    label: "Departamento de nacimiento",
    validation: yup.string().trim().uppercase().required(),
    list: "states-list",
  },
  {
    name: "address",
    label: "Dirección de residencia",
    validation: yup.string().trim().uppercase().required(),
  },
  {
    name: "city",
    label: "Ciudad de residencia",
    validation: yup.string().trim().uppercase().required(),
    list: "cities-list",
  },
  {
    name: "state",
    label: "Departamento de residencia",
    validation: yup.string().trim().uppercase().required(),
    list: "states-list",
  },
  {
    name: "cellPhone",
    label: "Teléfono celular",
    validation: yup.string().trim().required(),
  },
  {
    name: "phone",
    label: "Teléfono fijo",
    validation: yup.string().trim().required(),
  },
  {
    name: "dateFamily",
    label: "Día de la familia",
    type: "date",
    validation: yup.string().required(),
  },
  {
    name: "civilStatus",
    label: "Estado civil",
    type: "select",
    options: CIVIL_STATUS,
    validation: yup.string().required(),
  },
  {
    name: "pensionFund",
    label: "Fondo de pension",
    type: "select",
    options: PENSION_FUND,
    validation: yup.string().required(),
  },
  {
    name: "eps",
    label: "Eps",
    type: "select",
    options: EPS,
    validation: yup.string().trim().required(),
  },
  {
    name: "accountNumber",
    label: "Número de cuenta",
    validation: yup.string().trim().uppercase().required(),
  },
  {
    name: "accountType",
    label: "Tipo de cuenta bancaria",
    type: "select",
    options: ACCOUNT_TYPE,
    validation: yup.string().required(),
  },
  {
    name: "accountBank",
    label: "Banco",
    type: "select",
    options: ACCOUNT_BANK,
    validation: yup.string().trim().lowercase().required(),
  },
  {
    name: "arl",
    label: "ARL",
    options: ARL,
    validation: yup.string().trim().lowercase().required(),
  },
  {
    name: "hasElementarySchool",
    label: "Estudios de primaria",
    type: "switch",
    validation: yup.boolean().default(false),
  },
  {
    name: "hasHighSchool",
    label: "Estudios de secundaria",
    type: "switch",
    validation: yup.boolean().default(false),
  },
  {
    name: "hasUniversity",
    label: "Estudios universitarios",
    type: "switch",
    validation: yup.boolean().default(false),
  },
  {
    name: "hasGraduateDegree",
    label: "Estudios de postgrado",
    type: "switch",
    validation: yup.boolean().default(false),
  },
  {
    name: "hasMastersDegree",
    label: "Estudios de maestría",
    type: "switch",
    validation: yup.boolean().default(false),
  },
  {
    name: "hasDoctorateDegree",
    label: "Estudios de doctorado",
    type: "switch",
    validation: yup.boolean().default(false),
  },
  {
    name: "whereStudy",
    label: "Realizó estudios en",
    validation: yup.string().trim().lowercase().required(),
  },
  {
    name: "salary",
    label: "Salario",
    type: "number",
    validation: yup.number().required(),
  },
  {
    name: "bonus",
    label: "Bonificaciones",
    type: "number",
    validation: yup.number().required(),
    readonly: true,
  },
];
