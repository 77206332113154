import { ReactNode, useCallback } from "react";
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  Spinner,
  Text,
  Hide,
} from "@chakra-ui/react";

import { HamburgerIcon, CloseIcon, ChevronLeftIcon } from "@chakra-ui/icons";

import logo from "../images/ITG-2.svg";
import { unAuthenticate, setMode } from "../modules/auth/reducer";
import {
  NavLink as NavLinkRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { useAppDispatch, useAppSelector, useLogin } from "../hooks";
import { GoogleLogin } from "@react-oauth/google";
import { Role } from "types.generated";

const NavLink = ({ to, children }: { to: string; children: ReactNode }) => {
  return (
    <Link
      px={2}
      py={1}
      as={NavLinkRouter}
      rounded={"md"}
      _hover={{
        textDecoration: "none",
        bg: useColorModeValue("gray.200", "gray.700"),
      }}
      to={to}
    >
      {children}
    </Link>
  );
};

function Layout() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const auth = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { login, isLoading: isLogging } = useLogin();

  const location = useLocation();

  const toggleMode = useCallback(() => {
    if (auth.mode === Role.Employee) {
      dispatch(setMode(Role.Admin));
    } else {
      dispatch(setMode(Role.Employee));
    }
    navigate("/");
  }, [auth.mode, dispatch, navigate]);

  return (
    <Box bg={useColorModeValue("gray.100", "gray.900")} px={4}>
      <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
        <IconButton
          size={"md"}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label={"Open Menu"}
          display={{ md: "none" }}
          onClick={isOpen ? onClose : onOpen}
        />
        <HStack spacing={8} alignItems={"center"}>
          <Hide below="sm">
            <Box display="flex" justifyContent="column" alignItems="flex-end">
              <Image src={logo} height="34" />
              <Text ml="2" color="brand.100">
                Einstein
              </Text>
            </Box>
          </Hide>
          <HStack as={"nav"} spacing={4} display={{ base: "none", md: "flex" }}>
            {auth && location.pathname !== "/" && (
              <NavLink to="/">Inicio</NavLink>
            )}
          </HStack>
        </HStack>
        <Flex alignItems={"center"}>
          {isLogging && <Spinner />}
          {!auth.auth && !isLogging && (
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                if (credentialResponse.credential) {
                  login(credentialResponse.credential);
                }
              }}
              onError={() => {
                console.log("Login Failed");
              }}
            />
          )}
          {auth.auth && (
            <Menu>
              <MenuButton
                as={Button}
                rounded={"full"}
                variant={"link"}
                cursor={"pointer"}
                minW={0}
              >
                <Avatar size={"sm"} src={""} />
              </MenuButton>
              <MenuList>
                {auth.auth.user.roles.find((role) => role === Role.Admin) && (
                  <MenuItem onClick={toggleMode}>
                    Modo{" "}
                    {auth.mode === Role.Admin ? "empleado" : "administrador"}
                  </MenuItem>
                )}
                <MenuDivider />
                <MenuItem onClick={() => dispatch(unAuthenticate())}>
                  Salir
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </Flex>
      </Flex>

      {isOpen ? (
        <Box pb={4} display={{ md: "none" }}>
          <Stack as={"nav"} spacing={4}>
            {auth && location.pathname !== "/" && (
              <NavLink to="/">
                <ChevronLeftIcon /> Atrás
              </NavLink>
            )}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
}

export default Layout;
