import { isRejectedWithValue } from "@reduxjs/toolkit";
import { unAuthenticate } from "modules/auth/reducer";
import { REHYDRATE } from "redux-persist";
import { client } from "libs";
import { show as showAlert } from "./components/alert/reducer";
import { api as apiAuth } from "./modules/auth/api";

import { createListenerMiddleware, addListener } from "@reduxjs/toolkit";
import type { TypedStartListening, TypedAddListener } from "@reduxjs/toolkit";
import type { RootState, AppDispatch } from "./store";

export const listenerMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

export const startAppListening =
  listenerMiddleware.startListening as AppStartListening;

export type StartAppListeningOptions = Parameters<typeof startAppListening>[0];

export const addAppListener = addListener as TypedAddListener<
  RootState,
  AppDispatch
>;

// export const errorsMiddleware: Middleware = (api) => (next) => (action) => {
//   if (isRejectedWithValue(action)) {
//   }
//   return next(action);
// };

startAppListening({
  matcher: isRejectedWithValue,
  effect: async (action: any, listenerApi) => {
    // No escuchar mas si ocurre un error

    const errors = action.meta.baseQueryMeta?.response?.errors;
    let logout = false;
    if (errors && errors.length > 0) {
      for (const error of errors) {
        if (error.extensions?.code === "UNAUTHENTICATED") {
          logout = true;
        } else {
          listenerApi.dispatch(
            showAlert({
              title: "An error occurred.",
              description: error.message,
              status: "error",
            })
          );
        }
      }
    }
    // Salir si el token, ha expirado
    if (logout) {
      const auth = listenerApi.getState().auth.auth;
      if (auth) {
        const pathname = listenerApi.getState().router.location.pathname;
        listenerApi.dispatch(
          unAuthenticate(pathname !== "/" ? pathname : undefined)
        );
        listenerApi.dispatch(
          showAlert({
            description:
              "Su tiempo de sesión ha expirado, le invitamos de nuevo a hacer login usando su cuenta itglobers, para continuar.",
            status: "warning",
            closable: false,
            paths: [],
          })
        );
      }
    }
  },
});

startAppListening({
  type: REHYDRATE,
  effect: (_, listenerApi) => {
    const token = listenerApi.getState().auth.auth?.token;
    if (token) {
      client.setHeader("Authorization", `Bearer ${token}`);
      listenerApi.dispatch(apiAuth.endpoints.Ping.initiate());
    }
  },
});
