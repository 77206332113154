import { api } from "./api.generated";

export const injectedRtkApi = api.enhanceEndpoints({
  addTagTypes: ["FieldsByEmployee"],
  endpoints: {
    GetFieldsByEmployee: {
      providesTags: ["FieldsByEmployee"],
    },
    UpdateFieldsByEmployee: {
      invalidatesTags: ["FieldsByEmployee"],
    },
  },
});

export { injectedRtkApi as api };
export const {
  useGetFieldsByEmployeeQuery,
  useMyInformationFieldsQuery,
  useBuildCertificationMutation,
  useLazyGetFieldsByEmployeeQuery,
  useLazyMyInformationFieldsQuery,
  useUpdateFieldsByEmployeeMutation,
  useGetAvailablesCertificationsQuery,
  useLazyGetAvailablesCertificationsQuery,
} = injectedRtkApi;
