import { useAppDispatch, useAppSelector } from "../hooks";
import { Container, Box, Text } from "@chakra-ui/react";

import { useEffect } from "react";
import { setRedirect, unRedirect } from "modules/auth/reducer";
import { useLocation, useNavigate } from "react-router-dom";
import { Role } from "types.generated";

import { default as AdminActions } from "modules/admin/Actions";
import { default as EmployeeActions } from "modules/employee/Actions";

export default function Dashboard() {
  const { auth, redirect, mode } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (redirect && auth) {
      setTimeout(() => {
        dispatch(unRedirect());
        navigate(redirect);
      }, 1000);
    }

    if (!auth && !redirect && location.state?.from?.pathname) {
      dispatch(setRedirect(location.state?.from?.pathname));
    }
  }, [redirect, dispatch, navigate, auth, location.state?.from?.pathname]);

  if (!auth) {
    return (
      <Box p={4} justifyContent="center">
        <Container p="4" bg="gray.100" borderRadius="md">
          <Text fontWeight="semibold" color="blackAlpha.700">
            Bienvenido al sistema de recursos humanos de tu compañia, ITGLOBERS
          </Text>
        </Container>
      </Box>
    );
  }

  if (mode === Role.Admin) {
    return <AdminActions />;
  }

  return <EmployeeActions />;
}
