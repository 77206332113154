import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";
import { Role } from "types.generated";

export const isSuperSelector = createSelector(
  (state: RootState) => state.auth.auth?.user,
  (auth) => (auth && auth.roles.find((r) => r === Role.Super) ? true : false)
);

export const isAdminSelector = createSelector(
  (state: RootState) => state.auth.auth?.user,
  (auth) => (auth && auth.roles.find((r) => r === Role.Admin) ? true : false)
);

export const userSelector = createSelector(
  (state: RootState) => state.auth.auth?.user,
  (user) => user
);
