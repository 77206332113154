import {
  Box,
  Flex,
  Image,
  VStack,
  Heading,
  Button,
  Text,
  StackDivider,
  Center,
  Container,
  useMediaQuery,
} from "@chakra-ui/react";
import { useAppSelector } from "hooks";
import { useNavigate } from "react-router-dom";
import { Role } from "types.generated";

import banner from "../../images/banner.png";
import { useMyInformationFieldsQuery } from "./api";

function Actions() {
  const [isLargerThan1280] = useMediaQuery("(min-width: 1280px)");
  const { data: myInformationFields } = useMyInformationFieldsQuery();
  const navigate = useNavigate();

  const subRoles = useAppSelector((state) =>
    state.auth.auth
      ? state.auth.auth.user.roles.filter((e) => e !== Role.Employee)
      : []
  );

  return (
    <Center>
      <Container maxW="container.xl" pt="10">
        <Box>
          <Flex>
            {isLargerThan1280 && (
              <Image
                borderRadius="sm"
                maxW="400px"
                src={banner}
                fallbackSrc="https://via.placeholder.com/200"
              />
            )}
            <VStack
              flex="1"
              px="10"
              divider={<StackDivider borderColor="gray.200" />}
              spacing={4}
              align="stretch"
            >
              <Box fontSize={["sm", "md", "lg", "xl"]}>
                <Heading>SERVICIOS AL COLABORADOR</Heading>
                <Text>Aqui puedes gestionar los siguientes servicios</Text>
              </Box>

              <VStack spacing={4} align="stretch">
                <Button onClick={() => navigate("/employee/certifications")}>
                  Solicitar Certificado Laboral
                </Button>
                {/* <Button>Comprobante de Nomina/Pagos</Button> */}
                <Button onClick={() => navigate("/employee/vacations")}>
                  Vacaciones
                </Button>
                {/* <Button onClick={() => navigate("/employee/benefits")}> */}
                {/*   Solicitud de mis Beneficios */}
                {/* </Button> */}
                <Button onClick={() => navigate("/employee/absences")}>
                  Incapacidades o ausencias
                </Button>
                {myInformationFields?.myInformationFields?.value?.length >
                  0 && (
                  <Button onClick={() => navigate("/employee/my-information")}>
                    Información Personal
                  </Button>
                )}

                {subRoles.find((e) => e === Role.Leader) && (
                  <Button onClick={() => navigate("/employee/leader")}>
                    Opciones de lider
                  </Button>
                )}
              </VStack>
              <Text>
                Para otras solicitudes por favor contactar al equipo de RRHH
              </Text>
            </VStack>
          </Flex>
        </Box>
      </Container>
    </Center>
  );
}

export default Actions;
