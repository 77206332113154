import {
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Select,
} from "@chakra-ui/react";
import ModalForm from "components/ModalForm";
import { FIELDS_EMPLOYEE } from "modules/employees/model";
import { memo, useEffect } from "react";
import { useForm } from "react-hook-form";

interface AddFieldModalFormProps {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (values: any) => void;
}

function AddFieldModalForm({ isOpen, onClose, onAdd }: AddFieldModalFormProps) {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues: { Name: "", MappingFrom: undefined } });

  useEffect(() => {
    reset({
      Name: "",
      MappingFrom: undefined,
    });
  }, [isOpen, reset]);

  function onSubmit({ Name, MappingFrom }: any) {
    onAdd({
      Name,
      MappingFrom: MappingFrom ? MappingFrom : undefined,
    });
  }

  const MappingFromOptions = memo(function () {
    return (
      <>
        {FIELDS_EMPLOYEE.map((i) => (
          <option key={i.name} value={`Employee.${i.name}`}>
            {i.label}
          </option>
        ))}
      </>
    );
  });

  return (
    <ModalForm
      title="Campo personalizado"
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    >
      <FormControl isInvalid={errors.Name ? true : false}>
        <FormLabel htmlFor="Name">Nombre</FormLabel>
        <Input
          id="Name"
          placeholder="Etiqueta"
          {...register("Name", {
            required: "Campo requerido",
            minLength: { value: 3, message: "Minimum length should be 3" },
          })}
        />
        <FormHelperText>
          {errors.Name
            ? errors.Name.message
            : "Escriba un nombre que pueda usar para distinguir entre los demás campos."}
        </FormHelperText>
      </FormControl>
      <FormControl isInvalid={errors.MappingFrom ? true : false}>
        <FormLabel htmlFor="MappingFrom">
          Desea mapear el campo desde un empleado?
        </FormLabel>
        <Select
          id="MappingFrom"
          placeholder="Seleccione..."
          {...register("MappingFrom", {})}
        >
          <MappingFromOptions />
        </Select>
        <FormHelperText>
          Esto permitira seleccionar un empleado para precargar los datos desde
          un formulario
        </FormHelperText>
      </FormControl>
    </ModalForm>
  );
}

export default AddFieldModalForm;
