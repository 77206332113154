import { Outlet } from "react-router-dom";

import Header from "./Header";
import Foother from "./Footer";
import Alert from "./alert/Alert";

function Layout() {
  return (
    <div id="page-container">
      <div id="content-wrap" style={{ minHeight: "100vh" }}>
        <Header />
        <Alert />
        <Outlet />
      </div>
      <Foother />
    </div>
  );
}

export default Layout;
