import { Container, VStack } from "@chakra-ui/react";
import { ReactNode } from "react";

export default function ContentPage({ children }: { children: ReactNode }) {
  return (
    <Container maxW="container.xl" mt="5">
      <VStack alignItems="stretch" bg="gray.50" p="5">
        {children}
      </VStack>
    </Container>
  );
}
