import {
  Alert as AlertChakra,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
} from "@chakra-ui/react";
import { useAppSelector, useAppDispatch, useUpdateEffect } from "hooks";
import { hide } from "./reducer";
import { useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";

function Alert() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const state = useAppSelector((state) => state.alert.alert);
  const ref = useRef<HTMLDivElement>(null);

  useUpdateEffect(() => {
    let listener: any;
    if (state && state.duration) {
      listener = setTimeout(() => {
        dispatch(hide());
      }, state.duration);
    }

    return () => {
      if (listener) {
        clearTimeout(listener);
      }
    };
  }, [state, hide]);

  useEffect(() => {
    if (state && ref.current) {
      ref.current.scrollIntoView();
    }
  }, [state]);

  useEffect(() => {
    if (
      state &&
      state.paths &&
      state.paths.length !== 0 &&
      !state.paths.find(
        (path) =>
          path.replace(/\/$/, "") === location.pathname.replace(/\/$/, "")
      )
    ) {
      dispatch(hide());
    }
  }, [location, state, dispatch]);

  if (!state) return null;

  return (
    <AlertChakra status={state.status} variant="left-accent" ref={ref}>
      <AlertIcon />
      <Box width="100%">
        <AlertTitle>{state.title}</AlertTitle>
        <AlertDescription>{state.description}</AlertDescription>
      </Box>
      {state.closable && (
        <CloseButton
          alignSelf="flex-start"
          position="relative"
          right={-1}
          top={-1}
          onClick={() => dispatch(hide())}
        />
      )}
    </AlertChakra>
  );
}

export default Alert;
