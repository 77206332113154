import * as Types from '../../types.generated';

import { api } from 'libs';
module.hot?.accept();
export type MyInformationFieldsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyInformationFieldsQuery = { __typename?: 'Query', myInformationFields?: { __typename?: 'Setting', value?: any } };

export type GetFieldsByEmployeeQueryVariables = Types.Exact<{
  employeeId: Types.Scalars['ID'];
}>;


export type GetFieldsByEmployeeQuery = { __typename?: 'Query', getFieldsByEmployee: { __typename?: 'FieldsByEmployee', firstName?: string, middleName?: string, lastName?: string, secondLastName?: string, idNumber?: string, idType?: string, contractName?: string, positionName?: string, dateBirth?: any, personalEmail?: string, dateFamily?: any, stateBirth?: string, address?: string, city?: string, state?: string, cellPhone?: string, phone?: string, civilStatus?: string, pensionFund?: string, eps?: string, accountNumber?: string, accountType?: string, accountBank?: string, arl?: string, hasHighSchool?: boolean, hasElementarySchool?: boolean, hasUniversity?: boolean, hasGraduateDegree?: boolean, hasMastersDegree?: boolean, hasDoctorateDegree?: boolean, whereStudy?: string, custom?: any } };

export type UpdateFieldsByEmployeeMutationVariables = Types.Exact<{
  employeeId: Types.Scalars['ID'];
  data: Types.FieldsByEmployeeInput;
}>;


export type UpdateFieldsByEmployeeMutation = { __typename?: 'Mutation', updateFieldsByEmployee?: any };

export type GetAvailablesCertificationsQueryVariables = Types.Exact<{
  userId: Types.Scalars['ID'];
}>;


export type GetAvailablesCertificationsQuery = { __typename?: 'Query', getEmployeeByUserId?: { __typename?: 'Employee', id: string, certificationsAvailables: Array<{ __typename?: 'Certification', id: string, name: any }> } };

export type BuildCertificationMutationVariables = Types.Exact<{
  input: Types.BuildCertificationInput;
}>;


export type BuildCertificationMutation = { __typename?: 'Mutation', buildCertification: { __typename?: 'BuildCertificationResponse', data: string } };


export const MyInformationFieldsDocument = `
    query MyInformationFields {
  myInformationFields {
    value
  }
}
    `;
export const GetFieldsByEmployeeDocument = `
    query GetFieldsByEmployee($employeeId: ID!) {
  getFieldsByEmployee(employeeId: $employeeId) {
    firstName
    middleName
    lastName
    secondLastName
    idNumber
    idType
    contractName
    positionName
    dateBirth
    personalEmail
    dateFamily
    stateBirth
    address
    city
    state
    cellPhone
    phone
    civilStatus
    pensionFund
    eps
    accountNumber
    accountType
    accountBank
    arl
    hasHighSchool
    hasElementarySchool
    hasUniversity
    hasGraduateDegree
    hasMastersDegree
    hasDoctorateDegree
    whereStudy
    custom
  }
}
    `;
export const UpdateFieldsByEmployeeDocument = `
    mutation UpdateFieldsByEmployee($employeeId: ID!, $data: FieldsByEmployeeInput!) {
  updateFieldsByEmployee(employeeId: $employeeId, data: $data)
}
    `;
export const GetAvailablesCertificationsDocument = `
    query GetAvailablesCertifications($userId: ID!) {
  getEmployeeByUserId(userId: $userId) {
    id
    certificationsAvailables {
      id
      name
    }
  }
}
    `;
export const BuildCertificationDocument = `
    mutation BuildCertification($input: BuildCertificationInput!) {
  buildCertification(input: $input) {
    data
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    MyInformationFields: build.query<MyInformationFieldsQuery, MyInformationFieldsQueryVariables | void>({
      query: (variables) => ({ document: MyInformationFieldsDocument, variables })
    }),
    GetFieldsByEmployee: build.query<GetFieldsByEmployeeQuery, GetFieldsByEmployeeQueryVariables>({
      query: (variables) => ({ document: GetFieldsByEmployeeDocument, variables })
    }),
    UpdateFieldsByEmployee: build.mutation<UpdateFieldsByEmployeeMutation, UpdateFieldsByEmployeeMutationVariables>({
      query: (variables) => ({ document: UpdateFieldsByEmployeeDocument, variables })
    }),
    GetAvailablesCertifications: build.query<GetAvailablesCertificationsQuery, GetAvailablesCertificationsQueryVariables>({
      query: (variables) => ({ document: GetAvailablesCertificationsDocument, variables })
    }),
    BuildCertification: build.mutation<BuildCertificationMutation, BuildCertificationMutationVariables>({
      query: (variables) => ({ document: BuildCertificationDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };


