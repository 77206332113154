import { api as generatedApi } from "./api.generated";

import { client } from "libs";

export const api = generatedApi.enhanceEndpoints({
  endpoints: {
    Auth: {
      async onQueryStarted(arg, { queryFulfilled }) {
        const { data } = await queryFulfilled;
        client.setHeader("Authorization", `Bearer ${data.auth.token}`);
      },
    },
  },
});

export const { useAuthMutation } = api;
