import { createApi } from "@reduxjs/toolkit/query/react";
import {
  format,
  isDate,
  parse,
  parseISO,
  startOfWeek,
  getDay,
  isEqual,
  isBefore,
  isAfter,
} from "date-fns";
import { dateFnsLocalizer } from "react-big-calendar";
import es from "date-fns/locale/es";

// import { RootState } from "store";
import { isPlainObject } from "@reduxjs/toolkit";
// import type {BaseQueryFn} from "@reduxjs/toolkit/query";
// import type {DocumentNode} from "graphql";
import type { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {
  GraphQLClient,
  RequestOptions,
  RequestDocument,
  // ClientError,
} from "graphql-request";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { parsePhoneNumber } from "libphonenumber-js";

export type Document = RequestDocument;
export type RequestHeaders = RequestOptions["requestHeaders"];
export type PrepareHeaders = (
  headers: Headers,
  api: Pick<BaseQueryApi, "getState" | "endpoint" | "type" | "forced" | "extra">
) => MaybePromise<Headers>;

export type GraphqlRequestBaseQueryArgs = (
  | {
      url: string;
    }
  | { client: GraphQLClient }
) & {
  requestHeaders?: RequestHeaders;
  prepareHeaders?: PrepareHeaders;
};

export type QueryReturnValue<T = unknown, E = unknown, M = unknown> =
  | {
      error: E;
      data?: undefined;
      meta?: M;
    }
  | {
      error?: undefined;
      data: T;
      meta?: M;
    };
export type MaybePromise<T> = T | PromiseLike<T>;

// export const graphqlRequestBaseQuery = (
//   options: GraphqlRequestBaseQueryArgs
// ): BaseQueryFn<
//   {document: string | DocumentNode; variables?: any},
//   unknown,
//   Pick<ClientError, "name" | "message" | "stack">,
//   Partial<Pick<ClientError, "request" | "response">>
// > => {
//   const client =
//     "client" in options ? options.client : new GraphQLClient(options.url);
//   const requestHeaders: RequestHeaders =
//     "requestHeaders" in options ? options.requestHeaders : {};

//   return async (
//     {document, variables},
//     {getState, endpoint, forced, type, signal, extra}
//   ) => {
//     try {
//       const prepareHeaders: PrepareHeaders =
//         options.prepareHeaders ?? ((x) => x);
//       const headers = new Headers(stripUndefined(requestHeaders));

//       const preparedHeaders = await prepareHeaders(headers, {
//         getState,
//         endpoint,
//         forced,
//         type,
//         extra,
//       });

//       return {
//         data: await client.request({
//           document,
//           variables,
//           signal,
//           requestHeaders: preparedHeaders,
//         } as any),
//         meta: {},
//       };
//     } catch (error) {
//       if (error instanceof ClientError) {
//         const {name, message, stack, request, response} = error;
//         return {
//           error: {name, message, stack},
//           meta: {request, response},
//         };
//       }
//       throw error;
//     }
//   };
// };

export function stripUndefined(obj: any) {
  if (!isPlainObject(obj)) {
    return obj;
  }
  const copy: Record<string, any> = { ...obj };
  for (const [k, v] of Object.entries(copy)) {
    if (typeof v === "undefined") delete copy[k];
  }
  return copy;
}

export const client = new GraphQLClient(process.env.REACT_APP_URI_API);

export const api = createApi({
  reducerPath: "graphqlApi",
  baseQuery: graphqlRequestBaseQuery({
    client,
    // prepareHeaders: (headers, { getState }) => {
    //   // By default, if we have a token in the store, let's use that for authenticated requests
    //   const token = (getState() as RootState).auth.auth?.token;
    //   if (token) {
    //     headers.set("authorization", `Bearer ${token}`);
    //   }
    //   return headers;
    // }
  }),
  endpoints: () => ({}),
});

export function emptyStringToNull<T>(value: T, originalValue: any): T | null {
  if (typeof originalValue === "string" && originalValue.trim() === "") {
    return null;
  }

  return value;
}

export function stringToPhone(value: string): string {
  try {
    const parsePhone = parsePhoneNumber(value);
    if (!parsePhone.isValid() || !parsePhone.country) {
      return "";
    }
    return parsePhone.formatInternational();
  } catch {
    return "";
  }
}

export function emptyStringToFalse<T>(
  value: T,
  originalValue: any
): T | boolean {
  if (
    (typeof originalValue === "string" && originalValue.trim() === "") ||
    originalValue === null ||
    originalValue === undefined
  ) {
    return false;
  }

  return value;
}

export function emptyStringToZero<T>(value: T, originalValue: any): T | number {
  if (typeof originalValue === "string" && originalValue.trim() === "") {
    return 0;
  }

  return value;
}

export function stringToDate<T>(
  _value: T,
  originalValue: string
): string | Date {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, "yyyy-MM-dd", new Date());

  return parsedDate;
}

export const isDateValidator = (value: string) => isDate(parseISO(value));

export const dateFormat = (value: string | Date) => {
  if (typeof value === "string") {
    value = parseISO(value);
  }

  return format(value, "dd/MM/yyyy");
};

export function slugify(text: string): string {
  return text
    .toString() // Cast to string (optional)
    .normalize("NFKD") // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string (optional)
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w-]+/g, "") // Remove all non-word chars
    .replace(/--+/g, "-"); // Replace multiple - with single -
}

export function openGoogleDocument(documentId: string): void {
  window.open(
    `https://docs.google.com/document/d/${documentId}/edit`,
    "_blank"
  );
}

export const EmailRegex =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const URLRegex = /^(?!\/).+?\.[a-z]{2,}(?:\/.*)?$/iu;

export const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales: {
    es: es,
  },
});

export const isBetween = (
  date: Date,
  from: Date,
  to: Date,
  inclusivity: string = "()"
) => {
  if (!["()", "[]", "(]", "[)"].includes(inclusivity)) {
    throw new Error("Inclusivity parameter must be one of (), [], (], [)");
  }

  const isBeforeEqual = inclusivity[0] === "[",
    isAfterEqual = inclusivity[1] === "]";

  return (
    (isBeforeEqual
      ? isEqual(from, date) || isBefore(from, date)
      : isBefore(from, date)) &&
    (isAfterEqual ? isEqual(to, date) || isAfter(to, date) : isAfter(to, date))
  );
};

export const createBlobFromFile = (file: File): Promise<Blob> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const [, data] = (reader.result as string).split(",");

      const binary = atob(data);

      const array: number[] = [];

      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }

      resolve(new Blob([new Uint8Array(array)], { type: file.type }));
    };
  });

// NOTE: Download file base64
export const downloadFile = async (
  fileName: string,
  content: string
): Promise<void> => {
  return new Promise((resolve) => {
    const href = `data:application/pdf;base64,${content}`;
    const link = document.createElement("a");

    link.download = fileName;
    link.href = href;

    link.onclick = function () {
      const that = this as HTMLAnchorElement;
      setTimeout(function () {
        window.URL.revokeObjectURL(that.href);
        resolve();
      }, 1500);
    };

    link.click();
    link.remove();
  });
};

export const uploadFiles = (
  url: string,
  formData: FormData,
  onProgress: (progress: number) => void
) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener("progress", (e) =>
      onProgress(e.loaded / e.total)
    );
    xhr.addEventListener("load", () =>
      resolve({ status: xhr.status, body: xhr.responseText })
    );
    xhr.addEventListener("error", () =>
      reject(new Error("File upload failed"))
    );
    xhr.addEventListener("abort", () =>
      reject(new Error("File upload aborted"))
    );
    xhr.open("POST", url, true);
    xhr.send(formData);
  });
