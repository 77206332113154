import {
  Box,
  Flex,
  Image,
  VStack,
  Heading,
  Button,
  Text,
  StackDivider,
  Center,
  Container,
  useMediaQuery,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import banner from "../../images/banner.png";

function Actions() {
  const [isLargerThan1280] = useMediaQuery("(min-width: 1280px)");
  const navigate = useNavigate();

  return (
    <Center>
      <Container maxW="container.xl" pt="10">
        <Box>
          <Flex>
            {isLargerThan1280 && (
              <Image
                borderRadius="sm"
                maxW="400px"
                src={banner}
                fallbackSrc="https://via.placeholder.com/200"
              />
            )}
            <VStack
              flex="1"
              px="10"
              divider={<StackDivider borderColor="gray.200" />}
              spacing={4}
              align="stretch"
            >
              <Box fontSize={["sm", "md", "lg", "xl"]}>
                <Heading>SERVICIOS AL EMPLEADOR</Heading>
                <Text>Aqui puedes gestionar los siguientes servicios</Text>
              </Box>

              <VStack spacing={4} align="stretch">
                <Button onClick={() => navigate("/admin/certifications")}>
                  Certificados Laborales
                </Button>
                <Button onClick={() => navigate("/admin/payment-receipts")}>
                  Comprobante de Nomina
                </Button>
                <Button onClick={() => navigate("admin/vacations")}>
                  Vacaciones
                </Button>
                <Button onClick={() => navigate("admin/benefits")}>
                  Beneficios
                </Button>
                <Button onClick={() => navigate("/admin/disabilities")}>
                  Incapacidades o ausencias
                </Button>
                <Button onClick={() => navigate("/admin/employees")}>
                  Empleados
                </Button>
                <Button onClick={() => navigate("/admin/income")}>
                  Proceso de ingreso de empleado
                </Button>
                <Button onClick={() => navigate("/admin/configurations")}>
                  Configuraciones
                </Button>
              </VStack>
            </VStack>
          </Flex>
        </Box>
      </Container>
    </Center>
  );
}

export default Actions;
