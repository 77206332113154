import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
} from "@chakra-ui/react";

type Prompt = {
  text: string;
  callback?: (value: string) => void | Promise<void>;
  options?: string[];
};

type CurrentPromptContextType = (data: Prompt) => void;
const PromptContext = React.createContext<CurrentPromptContextType | null>(
  null
);
PromptContext.displayName = "PromptContext";

const PromptProvider = ({ children }: any) => {
  const [value, setValue] = useState<string>("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState<Prompt>({
    text: "Ingrese un valor",
  });

  const prompt = useCallback(
    (data: Prompt) => {
      setData(data);
      onOpen();
    },
    [onOpen]
  );

  useEffect(() => {
    if (isOpen) {
      setValue("");
    }
  }, [isOpen, setValue]);

  const onCompleted = useCallback(() => {
    if (data.callback) {
      data.callback(value);
    }
    onClose();
  }, [onClose, data, value]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
      setValue(e.target.value);
      if (e.target.value && data.options) {
        if (data.callback) {
          data.callback(e.target.value);
        }
        onClose();
      }
    },
    [data, setValue, onClose]
  );

  return (
    <PromptContext.Provider value={prompt}>
      <Modal isOpen={isOpen} onClose={onCompleted}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{data.text}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {data.options && data.options.length > 0 ? (
              <Select onChange={handleChange} value={value}>
                <option value="">Seleccione una opción</option>
                {data.options?.map((v, i) => (
                  <option key={i} value={v}>
                    {v}
                  </option>
                ))}
              </Select>
            ) : (
              <Input onChange={handleChange} value={value} />
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onCompleted}>
              Aceptar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {children}
    </PromptContext.Provider>
  );
};

const usePrompt = () => {
  const currentContext = useContext(PromptContext);
  if (!currentContext) {
    throw new Error("usePrompt has to be used within <PromptContext.Provider>");
  }

  const prompt = useCallback(
    (text: string, options?: string[]): Promise<string> =>
      new Promise((resolve) => {
        currentContext({
          text,
          callback: (result) => resolve(result),
          options,
        });
      }),
    [currentContext]
  );

  return prompt;
};

export { PromptProvider, usePrompt };
