import {
  ArrowBackIcon,
  CopyIcon,
  DeleteIcon,
  EditIcon,
  PlusSquareIcon,
} from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Button,
  Code,
  HStack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useUpdateEffect,
} from "@chakra-ui/react";
import { useAlert } from "components/alert/hooks";
import ContentPage from "components/ContentPage";
import Loading from "components/Loading";
import { useClipboard } from "hooks";
import { openGoogleDocument, slugify } from "libs";
import { isEqual } from "lodash";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaSave, FaTrash } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { DocumentType } from "types.generated";
import AddFieldModalForm from "./components/AddFieldModalForm";
import { api, useSoftDeleteDocumentMutation } from "./Documents";

export default function DocumentEditPage() {
  const alert = useAlert();
  const { copy } = useClipboard();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isFetching } = api.useDocumentQuery(
    { documentId: id! },
    { skip: !id }
  );

  // Control de modales
  const {
    isOpen: isOpenConfirmDelete,
    onOpen: onOpenConfirmDelete,
    onClose: onCloseConfirmDelete,
  } = useDisclosure();

  const {
    isOpen: isOpenAddFieldModalForm,
    onOpen: onOpenAddFieldModalForm,
    onClose: onCloseAddFieldModalForm,
  } = useDisclosure();
  // End control de modales

  const [deleteDocument, { isSuccess }] = useSoftDeleteDocumentMutation();
  const [edit] = api.useEditDocumentMutation();

  const cancelRef = React.useRef<any>();

  const onDelete = () => {
    deleteDocument({ id: id! });
  };

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm();

  const fields = watch("fields");

  useEffect(() => {
    if (data?.document) {
      setValue("name", data.document.name);
      setValue("fields", data.document.fields);
    }
  }, [data?.document, setValue]);

  useUpdateEffect(() => {
    if (isSuccess) {
      alert({
        description: "Documento Digital archivado exitosamente",
        status: "success",
        duration: 5000,
        paths: [],
      });
      navigate(-1);
    }
  }, [isSuccess, alert]);

  const onSubmit = handleSubmit(async (values: any) => {
    await edit({ id: id!, payload: { ...values } }).unwrap();
    alert({
      description: "Documento Digital modificado exitosamente",
      status: "success",
      duration: 5000,
    });
  });

  const addField = (value: any) => {
    if (!fields.find((f: any) => value.Name === f.Name)) {
      setValue("fields", [...fields, value]);
    }
    onCloseAddFieldModalForm();
  };

  const removeField = (value: any) => {
    setValue(
      "fields",
      fields.filter((f: any) => !(f.Name === value.Name))
    );
  };

  return (
    <ContentPage>
      <AddFieldModalForm
        onAdd={addField}
        isOpen={isOpenAddFieldModalForm}
        onClose={onCloseAddFieldModalForm}
      />
      <AlertDialog
        isOpen={isOpenConfirmDelete}
        leastDestructiveRef={cancelRef}
        onClose={onCloseConfirmDelete}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Archivar documento digital
            </AlertDialogHeader>
            <AlertDialogBody>
              A continuacion se procede a archivar este documento digital, al
              realizar esta accion, ya no se visualizara en la lista de
              documentos digitales a firmar
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => onCloseConfirmDelete()}>
                Cancelar
              </Button>
              <Button colorScheme="red" onClick={() => onDelete()} ml={3}>
                Borrar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <HStack spacing="24px" mb="5" justify="space-between">
        <Button leftIcon={<ArrowBackIcon />} onClick={() => navigate(-1)}>
          Atras
        </Button>
        <HStack>
          <Button
            colorScheme="blue"
            disabled={isEqual(fields, data?.document.fields)}
            isLoading={isSubmitting}
            onClick={onSubmit}
            leftIcon={<FaSave />}
          >
            Guardar
          </Button>
          <Button
            leftIcon={<EditIcon />}
            onClick={() => openGoogleDocument(data?.document.documentId!)}
          >
            Editar documento
          </Button>
          <Button
            leftIcon={<PlusSquareIcon />}
            onClick={onOpenAddFieldModalForm}
          >
            Agregar campo
          </Button>
          <Button
            colorScheme="red"
            onClick={onOpenConfirmDelete}
            leftIcon={<FaTrash />}
          >
            Archivar
          </Button>
        </HStack>
      </HStack>
      {isFetching ? (
        <Loading />
      ) : (
        <TableContainer>
          <Table variant="simple" bg="white">
            <TableCaption>{data?.document.name}</TableCaption>
            <Thead>
              <Tr>
                <Th>Campos</Th>
                <Th />
                <Th />
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {data?.document.type === DocumentType.JobOffers && (
                <Tr>
                  <Td>Firma del receptor</Td>
                  <Td>
                    <Code>{"{{{signature-recipient}}}"}</Code>
                  </Td>
                  <Td>-</Td>
                  <Td>
                    <Button
                      leftIcon={<CopyIcon />}
                      onClick={() => copy(`{{{signature-recipient}}}`)}
                    >
                      Copiar
                    </Button>
                  </Td>
                </Tr>
              )}
              {fields &&
                fields.map((i: any) => (
                  <Tr key={i.Name}>
                    <Td>{i.Name}</Td>
                    <Td>
                      <Code>{`{{${slugify(i.Name)}}}`}</Code>
                    </Td>
                    <Td>{i.MappingFrom ? <Badge>Empleado</Badge> : "-"}</Td>
                    <Td width="1">
                      <HStack spacing="2">
                        <Button
                          colorScheme="red"
                          leftIcon={<DeleteIcon />}
                          onClick={() => removeField(i)}
                        >
                          Quitar
                        </Button>
                        <Button
                          leftIcon={<CopyIcon />}
                          onClick={() => copy(`{{${slugify(i.Name)}}}`)}
                        >
                          Copiar
                        </Button>
                      </HStack>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </ContentPage>
  );
}
