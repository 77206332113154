import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "./store";
import { useCallback, useEffect, useRef, useState } from "react";

import { authenticate } from "./modules/auth/reducer";
import { useAlert } from "./components/alert/hooks";

import { useAuthMutation } from "modules/auth/api";
import { useBuildDocumentMutation } from "modules/admin/api";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

interface UseLoginResponse {
  login: (idToken: string) => void;
  isLoading: boolean;
}

export const useLogin = (): UseLoginResponse => {
  const [authByGoogleTokenId, { isLoading, data }] = useAuthMutation();

  const dispatch = useAppDispatch();
  const alert = useAlert();

  const login = useCallback(
    (idToken: string) => {
      if (!isLoading) {
        authByGoogleTokenId({ token: idToken });
      }
    },
    [isLoading, authByGoogleTokenId]
  );

  useUpdateEffect(() => {
    if (!isLoading && data) {
      alert({
        description:
          "Bienvenido al sistema de recursos humanos de tu compañía, ItGlobers",
      });
      dispatch(authenticate(data.auth.token));
    }
  }, [isLoading, data]);

  return { login, isLoading };
};

export const useUpdateEffect = (callback: () => void, deps: any[]) => {
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      return callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

/**
 * Construir pdf de un documento en google drive, con datos de un empleado
 * */
export const useBuildDocument = () => {
  const [isBuilding, setIsBuilding] = useState(false);
  const [build_] = useBuildDocumentMutation();
  const build = async (employeeId: string, documentBuild: any) => {
    setIsBuilding(true);
    try {
      const result = await build_({
        input: {
          employeeId,
          documentId: documentBuild.documentId,
        },
      }).unwrap();

      const href = `data:application/pdf;base64,${result.buildDocument.data}`;
      const link = document.createElement("a");

      link.download = documentBuild.name + ".pdf";
      link.href = href;

      link.onclick = function () {
        const that = this as HTMLAnchorElement;
        setTimeout(function () {
          window.URL.revokeObjectURL(that.href);
        }, 1500);
      };

      link.click();
      link.remove();
    } catch (error) {
      console.log(error);
    }

    setIsBuilding(false);
  };

  return { build, isBuilding };
};

export function useClipboard() {
  const alert = useAlert();
  const copy = useCallback(
    (text: string) => {
      navigator.clipboard.writeText(text);
      alert({
        description: "Copiado en portapapeles",
        duration: 1500,
      });
    },
    [alert]
  );

  return { copy };
}

export function useCurrency() {
  let { current } = useRef(
    new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
    })
  );

  return { format: current.format };
}
