import { Routes, Route } from "react-router-dom";
import { ReduxRouter } from "@lagunovsky/redux-react-router";
import { history } from "./store";
import { Role } from "types.generated";

import loadable from "@loadable/component";

import Dashboard from "components/Dashboard";
import Layout from "components/Layout";
import RequireAuth from "components/RequireAuth";
import Loading from "components/Loading";
import DocumentEditPage from "modules/documents/DocumentEditPage";

const BenefitsAdminLayout = loadable(
  () => import("modules/benefits/AdminLayout"),
  { fallback: <Loading /> }
);
const BenefitsMyRequestList = loadable(
  () => import("modules/benefits/MyRequestList"),
  { fallback: <Loading /> }
);
const BenefitsPendingRequestList = loadable(
  () => import("modules/benefits/PendingRequestList"),
  { fallback: <Loading /> }
);
const BirthdayCalendar = loadable(
  () => import("modules/admin/components/BirthdayCalendar"),
  { fallback: <Loading /> }
);
const CertificationsAdmin = loadable(
  () => import("./modules/admin/Certifications"),
  { fallback: <Loading /> }
);
const CertificationsEmployees = loadable(
  () => import("./modules/employee/Certifications"),
  { fallback: <Loading /> }
);
const Configurations = loadable(() => import("modules/configurations"), {
  fallback: <Loading />,
});
const Contracts = loadable(() => import("modules/admin/Contracts"), {
  fallback: <Loading />,
});
const CustomFields = loadable(() => import("modules/admin/CustomFields"), {
  fallback: <Loading />,
});
const DocumentsPage = loadable(
  () => import("modules/documents/DocumentsPage"),
  {
    fallback: <Loading />,
  }
);
const EmployeeForm = loadable(() => import("modules/admin/EmployeeForm"), {
  fallback: <Loading />,
});
const EmployeeLayout = loadable(() => import("modules/admin/EmployeeLayout"), {
  fallback: <Loading />,
});
const EmployeeTable = loadable(() => import("modules/admin/EmployeeTable"), {
  fallback: <Loading />,
});
const HolidaysList = loadable(() => import("modules/vacations/HolidaysList"), {
  fallback: <Loading />,
});
const Income = loadable(() => import("modules/income"), {
  fallback: <Loading />,
});
const MyInformationForm = loadable(
  () => import("./modules/employee/MyInformationForm"),
  { fallback: <Loading /> }
);
const PreApproveBenefit = loadable(
  () => import("modules/benefits/PreApproveRequest"),
  { fallback: <Loading /> }
);
const PreApproveRequest = loadable(
  () => import("modules/vacations/PreApproveRequest"),
  { fallback: <Loading /> }
);
const SendPaymentReceipts = loadable(
  () => import("modules/payment-receipts/SendPaymentReceipts"),
  { fallback: <Loading /> }
);
const VacationsAdminLayout = loadable(
  () => import("modules/vacations/AdminLayout"),
  { fallback: <Loading /> }
);
const VacationsCalendar = loadable(() => import("modules/vacations/Calendar"), {
  fallback: <Loading />,
});
const VacationsMyRequestList = loadable(
  () => import("modules/vacations/MyRequestList"),
  { fallback: <Loading /> }
);
const VacationsList = loadable(() => import("modules/vacations/List"), {
  fallback: <Loading />,
});

const DocusignPlugin = loadable(() => import("modules/plugins/Docusign"), {
  fallback: <Loading />,
});

const ItglobersSignPlugin = loadable(
  () => import("modules/plugins/ItglobersSign"),
  {
    fallback: <Loading />,
  }
);

const SlackPlugin = loadable(() => import("modules/plugins/Slack"), {
  fallback: <Loading />,
});

const ContractRequirementForm = loadable(
  () => import("modules/income/ContractRequirementForm"),
  {
    fallback: <Loading />,
  }
);

const ContractEditPage = loadable(() => import("modules/contracts/EditPage"));

const LeaderActions = loadable(() => import("modules/employee/LeaderActions"));

const MyAbsencesList = loadable(
  () => import("modules/absences/MyAbsencesList")
);

const AbsencesList = loadable(() => import("modules/absences/AbsencesList"));

const EmployeeVacationsPage = loadable(
  () => import("modules/vacations/VacationsForEmployeePage")
);

function App() {
  return (
    <ReduxRouter history={history}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Dashboard />} />
          <Route path="employee" element={<RequireAuth />}>
            <Route path="absences" element={<MyAbsencesList />} />
            <Route
              path="leader/*"
              element={<RequireAuth role={[Role.Leader]} />}
            >
              <Route path="*" element={<LeaderActions />} />
            </Route>
            <Route
              path="certifications"
              element={<CertificationsEmployees />}
            />
            <Route path="my-information" element={<MyInformationForm />} />
            <Route path="vacations">
              <Route index element={<VacationsMyRequestList />} />
              <Route path="pre-approve/:id" element={<PreApproveRequest />} />
            </Route>
            <Route path="benefits">
              <Route index element={<BenefitsMyRequestList />} />
              <Route path="pre-approve/:id" element={<PreApproveBenefit />} />
            </Route>
          </Route>
          <Route path="admin" element={<RequireAuth role={[Role.Admin]} />}>
            <Route path="disabilities" element={<AbsencesList />} />
            <Route path="certifications" element={<CertificationsAdmin />} />
            <Route path="documents">
              <Route index element={<DocumentsPage />} />
              <Route path=":id/edit" element={<DocumentEditPage />} />
            </Route>
            <Route path="vacations" element={<VacationsAdminLayout />}>
              <Route index element={<VacationsList />} />
              <Route path="calendar" element={<VacationsCalendar />} />
              <Route path="holidays" element={<HolidaysList />} />
            </Route>
            <Route path="benefits" element={<BenefitsAdminLayout />}>
              <Route index element={<BenefitsPendingRequestList />} />
            </Route>
            <Route path="employees" element={<EmployeeLayout />}>
              <Route index element={<EmployeeTable />} />
              <Route path="contracts">
                <Route index element={<Contracts />} />
                <Route path=":id/edit" element={<ContractEditPage />} />
              </Route>
              <Route path="documents" element={<DocumentsPage />} />
              <Route path="custom-fields" element={<CustomFields />} />
              <Route path="create" element={<EmployeeForm />} />
              <Route path="edit/:id" element={<EmployeeForm />} />
              <Route path=":id/vacations" element={<EmployeeVacationsPage />} />
              <Route path="birthday-calendar" element={<BirthdayCalendar />} />
            </Route>
            <Route path="payment-receipts" element={<SendPaymentReceipts />} />
            <Route path="income/*" element={<Income />} />
            <Route path="configurations/*">
              <Route path="*" element={<Configurations />} />
            </Route>
          </Route>
          <Route path="plugins">
            <Route path="docusign" element={<DocusignPlugin />} />
            <Route path="itglobers-sign" element={<ItglobersSignPlugin />} />
            <Route path="slack" element={<SlackPlugin />} />
          </Route>
          <Route path="public">
            <Route path="income">
              <Route
                path=":id/contract-requirement-form"
                element={<ContractRequirementForm />}
              />
            </Route>
          </Route>
        </Route>
      </Routes>
    </ReduxRouter>
  );
}

export default App;
