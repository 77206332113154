import { api } from "./Documents.generated";
// import { api as apiIncome } from "../income/Income";

export const injectedRtkApi = api.enhanceEndpoints({
  addTagTypes: ["Document", "Document-LIST"],
  endpoints: {
    Document: {
      providesTags: (result) =>
        result
          ? [
              {
                type: "Document" as const,
                id: result.document.id,
              },
            ]
          : ["Document"],
    },
    Documents: {
      providesTags: (result) =>
        result
          ? [
              ...result.documents.map(({ id }) => ({
                type: "Document-LIST" as const,
                id,
              })),
              "Document-LIST",
            ]
          : ["Document-LIST"],
      // onCacheEntryAdded: (args, { dispatch }) => {
      //   // TODO: Buscar una forma mas ordenada de organizar la api, para evitar
      //   // estas relaciones.
      //   dispatch(apiIncome.util.invalidateTags(["OfferJobs"]));
      // },
    },
    CreateDocument: {
      invalidatesTags: ["Document-LIST"],
    },
    EditDocument: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Document", id: arg.id },
        { type: "Document-LIST", id: arg.id },
        "Document-LIST",
      ],
    },
    SoftDeleteDocument: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Document", id: arg.id },
        { type: "Document-LIST", id: arg.id },
        "Document-LIST",
      ],
    },
  },
});

export { injectedRtkApi as api };
export const {
  useDocumentsQuery,
  useCreateDocumentMutation,
  useSoftDeleteDocumentMutation,
} = injectedRtkApi;
