import * as Types from '../../types.generated';

import { api } from 'libs';
module.hot?.accept();
export type AuthMutationVariables = Types.Exact<{
  token: Types.Scalars['ID'];
}>;


export type AuthMutation = { __typename?: 'Mutation', auth: { __typename?: 'AuthResponse', token: string, user: { __typename?: 'AuthUser', id: string, email: any, roles: Array<Types.Role>, employee?: { __typename?: 'AuthUserEmployeeInfo', id: string, idNumber: any } } } };

export type PingQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PingQuery = { __typename?: 'Query', ping?: any };


export const AuthDocument = `
    mutation Auth($token: ID!) {
  auth(token: $token) {
    token
    user {
      id
      email
      roles
      employee {
        id
        idNumber
      }
    }
  }
}
    `;
export const PingDocument = `
    query Ping {
  ping
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    Auth: build.mutation<AuthMutation, AuthMutationVariables>({
      query: (variables) => ({ document: AuthDocument, variables })
    }),
    Ping: build.query<PingQuery, PingQueryVariables | void>({
      query: (variables) => ({ document: PingDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };


